import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home";
import PrivacyPolicy from "./Components/PrivacyPolicy ";
import Footer from "./Components/Footer";
import About from "./Components/About";
import ContactUs from "./Components/ContactUs";
import Play from "./Components/Play";
import TermAndCondition from "./Components/TermAndCondition";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term&condition" element={<TermAndCondition />} />
          <Route path="/play" element={<Play />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
export default App;
