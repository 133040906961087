import React, { useState, useEffect } from "react";
import Loader from "./Loader";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to represent loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust delay as needed

    // Cleanup timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <section className="p-8 min-h-screen flex items-center justify-center bg-gray-50">
        <Loader />
      </section>
    );
  }

  return (
    <main className="p-8 bg-gray-100 min-h-screen">
      <div className="container mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-6">
          Privacy Policy
        </h1>
        <p className="text-gray-600 mb-4">Last Updated: 20 July 2024</p>
        <p className="text-gray-800 mb-8">
          At Way Forward, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.
        </p>
        <div className="space-y-6">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. Information We Collect
            </h2>
            <ul className="list-disc list-inside pl-6 text-gray-700">
              <li>
                <strong>Personal Information:</strong> We collect personal information such as your name, email address, and contact details when you register on our website or use our services.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect information about how you use our website, including your IP address, browser type, and access times.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. How We Use Your Information
            </h2>
            <ul className="list-disc list-inside pl-6 text-gray-700">
              <li>
                <strong>To Provide Services:</strong> We use your information to deliver and improve our consulting, training, and resource services.
              </li>
              <li>
                <strong>To Improve Our Services:</strong> We analyze usage data to enhance our website and service offerings.
              </li>
              <li>
                <strong>To Communicate:</strong> We use your contact information to send important updates and respond to your inquiries.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. Information Sharing
            </h2>
            <p className="text-gray-700 mb-4">
              We do not sell, trade, or otherwise transfer your personal information to outside parties, except as required by law or to trusted third parties who assist us in operating our website and services.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. Data Security
            </h2>
            <p className="text-gray-700 mb-4">
              We implement a variety of security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Changes to This Policy
            </h2>
            <p className="text-gray-700 mb-4">
              We may update this Privacy Policy from time to time. Changes will be posted on this page, and the effective date will be updated.
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              6. Contact Us
            </h2>
            <p className="text-gray-700 mb-4">
              If you have any questions about this Privacy Policy, please contact us at{" "}
              <a
                href="mailto:privacy@wayforward.site"
                className="text-primary-color hover:underline"
              >
                privacy@wayforward.site
              </a>.
            </p>
            <p className="text-gray-700">
              Thank you for visiting Way Forward. We value your privacy and are committed to protecting your personal information.
            </p>
          </section>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
