import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const TermAndCondition = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to represent loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust delay as needed

    // Cleanup timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <section className="p-8 min-h-screen flex items-center justify-center bg-gray-50">
        <Loader />
      </section>
    );
  }
  return (
    <main className="p-8 bg-gray-100 min-h-screen">
      <div className="container mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-6">
          Terms and Conditions
        </h1>
        <p className="text-gray-700 mb-6">Last Updated: 20 July 2024</p>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Introduction</h2>
          <p className="text-gray-700">
            Welcome to wayforward.site. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully before using our services.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Use of the Website</h2>
          <ul className="list-disc list-inside pl-6 text-gray-700 space-y-2">
            <li><strong>Eligibility:</strong> You must be at least 18 years old to use this website.</li>
            <li><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account information and password.</li>
            <li><strong>Prohibited Activities:</strong> You agree not to engage in any unlawful activities or use the website in a way that could harm wayforward.site or other users.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Intellectual Property</h2>
          <p className="text-gray-700">
            All content on this website, including text, graphics, logos, and software, is the property of wayforward.site and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our explicit permission.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Limitation of Liability</h2>
          <p className="text-gray-700">
            wayforward.site is not liable for any damages arising from the use of our website or services. We provide the website "as is" without any warranties of any kind.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Changes to Terms</h2>
          <p className="text-gray-700">
            We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on the website. Your continued use of the website constitutes your acceptance of the new terms.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
          <p className="text-gray-700">
            If you have any questions about these terms, please contact us at{" "}
            <a
              href="mailto:support@wayforward.site"
              className="text-primary-color hover:underline"
            >
              support@wayforward.site
            </a>.
          </p>
        </section>
      </div>
    </main>
  );
};

export default TermAndCondition;
