import React from "react";

const Loader = () => (
  <div className="flex items-center justify-center h-full">
    <div className="relative flex items-center">
      <div className="w-3 h-12 bg-primary-color mx-1 rounded animate-bounce"></div>
      <div className="w-3 h-12 bg-secondary-color mx-1 rounded animate-bounce animation-delay-200"></div>
      <div className="w-3 h-12 bg-accent-purple mx-1 rounded animate-bounce animation-delay-400"></div>
    </div>
  </div>
);

export default Loader;
