import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to represent loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust delay as needed

    // Cleanup timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <section className="p-8 min-h-screen flex items-center justify-center bg-gray-50">
        <Loader />
      </section>
    );
  }
  return (
    <main className="p-8 bg-gray-100 min-h-screen">
      <div className="container mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-6">About Us</h1>
        <p className="text-gray-700 mb-6">
          Welcome to Way Forward! At Way Forward, we are dedicated to helping individuals and organizations navigate their paths to success and growth. Our mission is to provide valuable resources, insights, and support to guide you towards achieving your goals and aspirations.
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Story</h2>
          <p className="text-gray-700">
            Founded in 2024, Way Forward was created with the vision of offering practical solutions and strategic guidance to support personal and professional development. We are committed to empowering our users with the tools and knowledge they need to move forward confidently.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Team</h2>
          <p className="text-gray-700">
            Our team consists of experienced professionals with diverse backgrounds in various fields. We work together to deliver high-quality services and resources tailored to your specific needs. Our goal is to ensure that you have the support necessary to achieve your objectives.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Services</h2>
          <ul className="list-disc list-inside pl-6 text-gray-700">
            <li><strong>Consulting Services:</strong> Professional consulting to help you develop and implement effective strategies.</li>
            <li><strong>Training and Workshops:</strong> A variety of training programs and workshops designed to enhance your skills and knowledge.</li>
            <li><strong>Resources and Tools:</strong> Access to a range of resources and tools to support your personal and professional growth.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Get In Touch</h2>
          <p className="text-gray-700">
            Thank you for choosing Way Forward. We are excited to support your journey and help you achieve your goals. If you have any questions, feedback, or just want to chat, feel free to reach out to us at{" "}
            <a
              href="mailto:support@wayforward.site"
              className="text-primary-color hover:underline"
            >
              support@wayforward.site
            </a>.
          </p>
        </section>
      </div>
    </main>
  );
};

export default About;
