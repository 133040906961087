import { Link } from "react-router-dom";

// src/components/Footer.js
const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-primary-color text-white p-6 flex flex-col items-center">
      <div className="container max-w-6xl text-center">
        <p className="text-lg font-semibold">
          &copy; {new Date().getFullYear()} WayForward.site. All Rights
          Reserved.
        </p>
        <div className="mt-4">
          <Link
            to="/privacy-policy"
            onClick={handleLinkClick}
            className="text-white hover:text-accent-pink transition duration-300"
          >
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link
            to="/term&condition"
            onClick={handleLinkClick}
            className="text-white hover:text-accent-pink transition duration-300"
          >
            Terms and Conditions
          </Link>{" "}
          |{" "}
          <Link
            to="/contact-us"
            onClick={handleLinkClick}
            className="text-white hover:text-accent-pink transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
