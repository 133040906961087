import React, { useState, useEffect } from "react";
import Loader from "./Loader"; // Import your loader component
import Games from "../Const/Games";
import { Link } from "react-router-dom";

// Predefined colors from Tailwind CSS configuration
const predefinedColors = [
  "bg-primary-color", // Neon Blue
  "bg-secondary-color", // Vivid Purple
  "bg-accent-purple", // Purple
  "bg-accent-pink", // Pink
  "bg-accent-teal", // Teal
];

// Function to get random games
const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

// Function to pick a random color class from predefined colors
const getRandomColorClass = () => {
  const randomIndex = Math.floor(Math.random() * predefinedColors.length);
  return predefinedColors[randomIndex];
};

// Function to generate a random hex color
const getRandomHexColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Home = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  // Create arrays of random color classes and hex colors
  const [buttonColors] = useState(
    Array.from({ length: 12 }, getRandomHexColor)
  );
  const [titleColors] = useState(
    Array.from({ length: 12 }, getRandomColorClass)
  );
  const [borderColors] = useState(
    Array.from({ length: 12 }, getRandomColorClass)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const randomGames = getRandomGames(Games, 12);
      setGames(randomGames);
      setLoading(false);
    }, 800); // Simulate a delay for loading

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="p-8 min-h-screen flex items-center justify-center bg-gray-50">
      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-7xl">
          {games.map((game, index) => (
            <div
              key={index}
              className="relative rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl group"
            >
              <div>
                <img
                  src={game.image}
                  alt={`Game ${index + 1}`}
                  className="w-full h-64 object-cover"
                />
                <div
                  className={`absolute inset-x-0 bottom-0 p-4 text-center ${titleColors[index]} bg-opacity-80 transition-transform transform group-hover:translate-y-full group-hover:opacity-0 z-10`}
                  style={{
                    borderTop: `2px solid ${borderColors[index]}`,
                  }}
                >
                  <h2 className="text-xl font-bold text-white">{game.title}</h2>
                </div>
              </div>
              <div className="absolute inset-0 flex flex-col justify-center items-center p-4 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20">
                {/* <p className="text-white text-center mb-4 px-2">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: truncateHTML(game.description, 100),
                    }}
                  />
                </p> */}
                <Link
                  to={`/play?play=${game?.gameurl}`}
                  className="text-white px-6 py-3 rounded-full transition duration-300 shadow-md hover:shadow-lg"
                  style={{ backgroundColor: buttonColors[index] }}
                >
                  Play Now
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Home;
